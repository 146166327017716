import React from 'react'
import logo from './logo.svg'
import demo_img from './demo_ipass.png'
import './App.css'

const TAILWIND = {
    BG_COLOR: 'bg-slate-800',
    TEXT_COLOR: 'text-white',
    TEXT_COLOR_STRONG: 'text-orange-600',
    TEXT_COLOR_LINK: 'text-sky-400',
}
function App() {
    const tailwind_style =
        'min-h-screen flex flex-col items-center justify-center text-lg gap-y-6' +
        ` ${TAILWIND.BG_COLOR} ${TAILWIND.TEXT_COLOR}`
    const link_style = `${TAILWIND.TEXT_COLOR_LINK}`
    return (
        <div className={tailwind_style}>
            <img src={logo} className="App-logo" alt="logo" />
            <div className="text-4xl font-bold">ysdev(mmdev)</div>
            {/*github */}
            <div className="text-2xl font-bold">Links</div>
            <div>
                <div>
                    <a
                        className={link_style}
                        href="https://github.com/mmdev110"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        github
                    </a>
                </div>
                <div>
                    <div>
                        <a
                            className={link_style}
                            href="https://music-tools.ys-dev.net/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            music_tools
                        </a>
                    </div>
                    <div className="pl-6">
                        <div>
                            <a
                                className={link_style}
                                href="https://github.com/mmdev110/music_tools_frontend"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                github(frontend)
                            </a>
                        </div>
                        <div>
                            <a
                                className={link_style}
                                href="https://github.com/mmdev110/music_tools_backend"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                github(backend)
                            </a>
                        </div>
                        <div>
                            <a
                                className={link_style}
                                href="https://github.com/mmdev110/music_tools_infra"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                github(infra)
                            </a>
                        </div>
                        <div>
                            <div>
                                技術のアウトプットのために制作しているサイトです。
                                <br />
                                楽曲分析によって音楽制作のハードルを下げることを目的としています。
                            </div>
                            <div>・主な使用技術</div>
                            <ul className="pl-6">
                                <li>Golang</li>
                                <li>MySQL</li>
                                <li>Docker</li>
                                <li>React(Typescript)</li>
                                <li>Tailwind CSS</li>
                                <li>
                                    AWS
                                    <ul className="pl-6">
                                        <li>ECS</li>
                                        <li>Cloudfront</li>
                                        <li>RDS</li>
                                        <li>S3</li>
                                        <li>Lambda</li>
                                        <li>MediaConvert</li>
                                        <li>SES</li>
                                    </ul>
                                </li>
                                <li>Terraform</li>
                                <li>Github Actions</li>
                            </ul>
                            <div>
                                一部機能がログイン必須のため、動作確認のためのデモアカウントを用意しております。
                                <div className="pl-6">
                                    <img src={demo_img} alt="demo_ipass" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <a
                            className={link_style}
                            href="https://ysdev.hatenablog.com/"
                            target="_blank"
                            rel="noopener"
                        >
                            ブログ
                        </a>
                    </div>
                    <div className="pl-6">
                        学んだ内容を備忘として残しています。
                    </div>
                </div>
            </div>
            <div className="text-2xl font-bold">Certifications</div>
            <div>
                <ul className="list-inside">
                    <li>
                        AWS
                        <ul className="list-inside pl-6">
                            <li>SAA (2021/06)</li>
                            <li>SAP (2021/07)</li>
                            <li>DVA (2021/08)</li>
                            <li>DOP (2021/08)</li>
                        </ul>
                    </li>
                    <li>
                        Kubernetes
                        <ul className="list-inside pl-6">
                            <li>CKA (2021/10)</li>
                            <li>CKS (2023/03)</li>
                        </ul>
                    </li>
                    <li>Terraform Associate(002) (2021/09)</li>
                    <li>統計検定2級(2021/09)</li>
                    <li>TOEFL iBT: 82 (2020/10)</li>
                    <li>TOEIC L&R: 835 (2018/01)</li>
                    <li>応用情報技術者 (2017/12)</li>
                    <li>
                        Coursera
                        <ul className="list-inside pl-6">
                            <li>
                                <a
                                    className={link_style}
                                    href="https://www.coursera.org/learn/build-a-computer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Build a Modern Computer from First
                                    Principles: From Nand to Tetris
                                </a>{' '}
                                (2021/08)
                            </li>
                            <li>
                                <a
                                    className={link_style}
                                    href="https://www.coursera.org/learn/probability-statistics"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Probability and Statistics: To p or not to
                                    p?
                                </a>{' '}
                                (2020/10)
                            </li>
                            <li>
                                <a
                                    className={link_style}
                                    href="https://www.coursera.org/specializations/mathematics-machine-learning"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Mathematics for Machine Learning
                                    Specialization
                                </a>
                                <ul className="list-inside pl-6">
                                    <li>
                                        Mathematics for Machine Learning: Linear
                                        Algebra (2020/09)
                                    </li>
                                    <li>
                                        Mathematics for Machine Learning:
                                        Multivariate Calculus (2020/10)
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a
                                    className={link_style}
                                    href="https://www.coursera.org/specializations/cs-fundamentals"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Accelerated Computer Science Fundamentals
                                    Specialization
                                </a>
                                <ul className="list-inside pl-6">
                                    <li>
                                        Object-Oriented Data Structures in C++
                                        (2020/07)
                                    </li>
                                    <li>Ordered Data Structures (2020/07)</li>
                                    <li>Unordered Data Structures (2020/07)</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default App
